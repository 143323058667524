import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import '../assets/style.css';
import { Row, Col, Container, Button, Figure} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulWorkSuccessStories:{
        nodes:cstudies
    }} = data

    return (
        <div className="case-study">  
            <Container> 
                <div className="our-cs">
                    <h2 className="title">Success story</h2> 
                </div>
                {cstudies.map((cs)=>{
                return <Row className="cs-row"  style={{height: "100%"}}>
                    <Col xs={12} md="12" lg="6" className="cs-column">
                        <Row style={{height: "100%"}}>
                            <div className="both-images">
                                <Image className="cs-img" style={{height: "100%"}} fluid={cs.csImage.fluid} alt="Case img"></Image>
                                <div className="cs-top-img" style={{display:"none"}}> 
                                    <div className="top-img"> 
                                        {/* <Image  fluid={cs.csTopImage.fluid} alt="Case img"></Image> */}
                                    </div>
                                </div>
                            </div>
                            
                        </Row>
                    </Col>
                    <Col xs={12} md="12" lg="6"  className="cs-column-2">
                        <div className="right-text">
                            <h2 className="study-title">{cs.csTitle}</h2>
                            {cs.description.description &&<p className="study-subtitle">{cs.description.description}</p> } 
                            <Button href={`/work/${cs.csSlug}/`} className="secondary-btn-s"> View More
                                <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                </Figure>
                            </Button>
                        </div>
                    </Col> 
                </Row>
                })}
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulWorkSuccessStories(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt,  order: DESC}, limit:1) {
        nodes {
        updatedAt(formatString: "D MMMM YYYY")
        id
        csSlug
        csTitle
        description{
            description
        }
        csTopImage {
            fluid(quality: 100) {
            ...GatsbyContentfulFluid
            }
        }
        csImage{
            fluid(quality: 100){
            ...GatsbyContentfulFluid
            }
        }
        csBtn
        }
    }
}
`


