import React, { useState }  from "react"
import '../assets/style.css';
import { Row, Col, Container,Button} from 'react-bootstrap';
import banner from '../assets/images/services/te-banner.png';
import ContactUs from "./ContactUs";

export default function() {

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="banner-te single-service-banner">  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">Technology & Engineering</h1>
                        <p className="paragraph">Engineering platforms that deliver unparalleled customer experiences using insights, the latest technologies, and advanced data-driven strategies to create business value for our partners.</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        > Contact us </Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img">
                        <img src={banner} alt="te banner" />
                    </Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
}



