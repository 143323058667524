import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Col, Container} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulTheTechStackWeUse:{
        nodes:lp
    }} = data

    return (
        <div className="platforms">  
        {lp.map((lp)=>{
        return <>
            <Container>  
                <div className="content-box">
                    <p className="title">{lp.title}</p> 
                    <p className="subtitle">{lp.subtitle}</p>
                    <div className="platforms-col">
                        {lp.platforms.map((platforms, index) => ( 
                            <img  style={{ maxWidth: "100%", maxHeight: "60px" }} src={platforms.fluid.src} alt="Logo" key={index} />
                        ))}
                    </div>
                </div>
            </Container>
            </>
            })}
            </div>

    )
}

export const query = graphql`
  {
    allContentfulTheTechStackWeUse(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        title
        subtitle
        platforms {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
  }
`


