
import React from "react"
import { Container, Button, Figure} from "react-bootstrap";
import ContactUs from "./ContactUs";


const ComponentName = () => {

return <div className="mshs-box">
    <Container> 
        <div className="mshs-box-container"> 
        <div className="grid-container">
            <div className="box-image">
                <div className="box-img">
                    <img  style={{ maxWidth: "100%", maxHeight: "100%" }} src="https://assets.sogody.co.uk/sogody/revamp/shared-services.png" alt="Logo" />
                </div>
            </div>
            <div className="box-content">
                <p className="box-title">Always-on Managed Services</p>
                <p className="te-description">A partnership framework between you and Sogody, where we help you create an agile and innovative digital foundation for driving continuous success.</p>
                    
                <Button href="/services/managed-shared-services/"  className="secondary-btn-s mshs-build-your-pack">Read More
                    <Figure className="read-more">
                            <Figure.Image   src={require('../assets/images/next_service.png')}  />
                    </Figure>
                </Button>
            </div>
            
        </div>

        </div>
    </Container>
</div>
}


export default ComponentName

