import React from 'react'
import Layout from "../../components/Layout"
import TEBox from "../../components/TEBox";
import Platforms from "../../components/Platforms";
import { Seo } from '../../components/Seo'
// import myImg from '../../public/te-og.png'
import WWOTE from '../../components/WWOTE'
import CaseStudy from '../../components/CaseStudy'
import TeApproach from '../../components/our-approach/TeApproach'
import BannerTE from '../../components/BannerTE'
import TEBoxUnder from "../../components/TEBoxUnder";
import FAQTE from '../../components/FAQTE';
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>
        {/*  image={'https://sogody.com'+myImg} */}
        <Seo 
            title="Technology & Engineering - Sogody"
            description="We partner with your team to build and implement efficient technology solutions to deliver real platform value that fuels your digital business."
            url={url}
        />
        <div className="technology-engineering-page">  
            <BannerTE/>
            {/* <TEBoxUnder/> */}
            <WWOTE/>
            <TeApproach/>
            <TEBox/>
            {/* <SharedServicesPacks/> */}
            {/* <CaseStudy/> */}
            {/* <Container>
                <CtaMeet/> 
            </Container>  */}
            <Platforms/>
            <CaseStudy/>
            {/* <FAQTE/> */}
        </div>
    </Layout>
    )
}


