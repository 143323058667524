
import React from "react"
import '../../assets/style.css';
import {  Container} from 'react-bootstrap';
import { Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Pagination } from "swiper";

// import "swiper/css"

const TeApproach=() => {

    const settings = {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 20,
        // loop: true,
        pagination: { clickable: true },
        noSwiping: false,
        breakpoints: {
          567: {
            centeredSlides: false,
            slidesPerView: 2.5,
            spaceBetween: 30,
            // loop: true,
            paginationClickable : true,
          },
          1200: {
            centeredSlides: false,
            pagination: false,
            spaceBetween: 35,
            slidesPerView: 3.5,
            // noSwiping: false,
            // paginationClickable : true,
            // noSwipingClass:"swiper-wrapper",
          }
        }
      };

    const te = [
        {title:"Technology", description:"Sogody provides the tech that your company needs: developing, upgrading, and adjusting around your business.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-1.png" },
        {title:"Architecture",description:"We help you to promote micro-service-based API architectures that maximize the flexibility of data, and the continuous and effortless delivery of new features, products, and services.",imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-2.png" }, 
        {title:"Expertise",description:"Our coding teams have the expertise to deliver end-to-end digital platforms that interact at every touchpoint of the consumer journey, from websites to apps.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-3.png"},       
        {title:"UX Consultation",description:"We ensure digital projects get off to a smooth start by providing continuous UX consultation throughout each stage of the process, from public or user-facing features right through to back-end administrative functions.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-4.png"}, 
        {title:"Flexible",description:"We design and develop intelligent, scalable, and flexible web and app platforms that offer exceptional user experience.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-5.png"},    
        {title:"Data Analytics",description:"Your business has a wealth of data to draw on. We help you extract valuable insights, with the expertise to make sure they are relevant and actionable to drive your business.", imgUrl: "https://assets.sogody.co.uk/sogody/revamp/te-6.png"},    
    ]
    
    return (
        <div className="our-approach-te">  
            <Container> 
                <div className="te-head">
                    <h1>Our approach</h1> 
                </div>
            <Swiper {...settings}  pagination={{clickable: true}} modules={[Pagination]}>
            {te.map((t)=>{
                return <SwiperSlide>
                <div className="te-content">
                    <div className="sv-desktop">
                        <h2 className="title">{t.title}</h2>
                        <p className="description">{t.description}</p>
                    </div>

                    <div className="sv-mobile">
                        <h2 className="title">{t.title}</h2>
                        <ReactReadMoreReadLess
                            charLimit={120}
                            readMoreText={"Read more"}
                            readLessText={"Hide"}
                        >
                        {t.description}
                        </ReactReadMoreReadLess>
                    </div>
                        <img src={t.imgUrl}></img>
                    </div> 
                    </SwiperSlide>
                })}
                </Swiper>
            </Container>
        </div>
    )
}

export default TeApproach;